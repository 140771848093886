import React, { createContext, useEffect } from "react"
import { useAuth } from "../hooks/useAuth"
import { useNavigate } from "react-router-dom"
import { AuthContextType, Auth } from " types/AuthTypes"
import { Dispatch, SetStateAction } from "react"

const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  user: null,
  setIsAuthenticated: async () => {},
  setUser: async () => {},
  signIn: async () => {},
  signOut: async () => {},
  signUp: async () => {},
  storedUser: null,
  storedToken: null,
})

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate()
  const { signIn, signUp, signOut, isAuthenticated, user, setIsAuthenticated, setUser, storedUser, storedToken } =
    useAuth() as {
      signIn: (payload: Record<string, any>) => Promise<any>
      signUp: (payload: Record<string, any>) => Promise<any>
      signOut: () => Promise<any>
      isAuthenticated: boolean
      user: Auth | null
      setIsAuthenticated: Dispatch<SetStateAction<boolean>>
      setUser: Dispatch<SetStateAction<Auth | null>>
      storedUser: Auth | null
      storedToken: string | null
    }

  useEffect(() => {
    if (storedToken) {
      setIsAuthenticated(true)
      setUser(storedUser)
    }
  }, [storedToken, storedUser, navigate])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        setIsAuthenticated,
        setUser,
        signIn,
        signOut,
        signUp,
        storedUser,
        storedToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
